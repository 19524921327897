const jsonPortarias = [
  /*

    Diferente de Atos administrativos cada Tipo é uma rota diferente

*/
  {
    TITLE: "Edital e Licitações",
    ROUTE: "Início / Licitações e contratos / Edital",
    SELECT: "licitacoes-e-editais",
  },
  {
    TITLE: "Avisos",
    ROUTE: "Início / Licitações e contratos / Avisos",
    SELECT: "avisos",
  },
  {
    TITLE: "Dispensas",
    ROUTE: "Início / Licitações e contratos / Dispensas",
    SELECT: "dispensas-e-inexigibilidade/0",
    "SELECT-TYPE-DECLARACAO": "Dispensa",
  },
  {
    TITLE: "Inexigibilidade",
    ROUTE: "Início / Licitações e contratos / Inexigibilidade",
    SELECT: "dispensas-e-inexigibilidade/1",
    "SELECT-TYPE-DECLARACAO": "Inexigibilidade",
  },
  {
    TITLE: "Pregão Eletrônico",
    ROUTE: "Início / Licitações e contratos / Pregão Eletrônico",
    SELECT: "pregao-eletronico",
    "SELECT-TYPE-DECLARACAO": "Pregão Eletrônico",
  },
  {
    TITLE: "Obras Públicas",
    ROUTE: "Início / Licitações e contratos / Obras Públicas",
    SELECT: "obras-publicas",
    "SELECT-TYPE-DECLARACAO": "Obras Públicas",
  },
  {
    TITLE: "Adesão de Ata - SRP",
    ROUTE: "Início / Licitações e contratos / Adesão de Ata - SRP",
    SELECT: "ata-de-adesao",
    "SELECT-TYPE-DECLARACAO": "Ata de Adesão - SRP",
  },
  {
    TITLE: "Resultado do Certame",
    ROUTE: "Início / Licitações e contratos / Resultado do Certame",
    SELECT: "resultado-certame",
  },
  {
    TITLE: "Termo de Aditivo",
    ROUTE: "Início / Licitações e contratos / Termo de Aditivo",
    SELECT: "aditivos",
    "SELECT-TYPE-DECLARACAO": "Termo de Aditivo",
  },
  {
    TITLE: "Convênio",
    ROUTE: "Início / Licitações e contratos / Convênio",
    SELECT: "convenio",
    "SELECT-TYPE-DECLARACAO": "Convênio",
  },
  {
    TITLE: "Contratos",
    ROUTE: "Início / Licitações e contratos / Contratos",
    SELECT: "contratos",
  },
  {
    TITLE: "Desertas ou Fracassadas",
    ROUTE: "Início / Licitações e contratos / Desertas ou Fracassadas",
    SELECT: "desertas-fracassadas",
    "SELECT-TYPE-DECLARACAO": "Deserta/Fracassada",
  },
  {
    TITLE: "",
    ROUTE: "Início / Licitações e contratos / ",
    SELECT: "",
  },
];

export default jsonPortarias;
